<template>
  <div class="policy-regulation-detail container">
    <!-- 文章 -->
    <article>
      <div class="title">{{articleHtml.title}}</div>
      <div class="time">{{articleHtml.modifyTime|filterTime}}</div>
      <div class="content" v-html="articleHtml.content"></div>
    </article>

    <!-- 附件列表 -->
    <div class="file-list">
      <h3>附件列表</h3>
      <a
        v-for="(v,i) in fileList"
        :key="i"
        :href="baseURL+v"
        target="_blank"
      >{{ v.substring( v.lastIndexOf('/')+1 ) }}</a>
    </div>
  </div>
</template>

<script>
import policyRegulationApi from "@/api/policyRegulation";
import { mapState } from "vuex";

export default {
  async created() {
    // 获取政策法规id
    this.id = this.$route.query.id;
    let { data } = await policyRegulationApi.getPolicyRegulationDetail({
      id: this.id
    });
    this.articleHtml = data;
    this.fileList = JSON.parse(data.attachFilePath);
  },
  data() {
    return {
      id: 0, //根据id来获取详情
      articleHtml: {},
      fileList: []
    };
  },
  computed: {
    ...mapState(["baseURL"])
  },
  filters: {
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    }
  }
};
</script>

<style lang="less" scoped>
.policy-regulation-detail {
  // 文章
  article {
    padding: 30px;
    .title {
      text-align: center;
      font-size: 25px;
    }
    .time {
      text-align: center;
      color: #979797;
      padding: 20px 0;
      border-bottom: 1px dashed #979797;
    }
    .content {
      padding-top: 10px;
    }
  }

  //  附件列表
  .file-list {
    padding-left: 30px;
    margin-bottom: 30px;
    h3 {
      font-size: 20px;
    }
    a {
      color: #3660d9;
      display: block;
      line-height: 20px;
    }
  }
}
</style>