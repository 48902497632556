/* 
  政策法规相关接口
*/

import req from '@/utils/request' //引入暴露出来的请求函数

export default {
  // 获取政策法规列表
  findPolicyRegulationList: params => req.get('/api/policyLaw/list/all', params),
  // 获取政策法规详情
  getPolicyRegulationDetail: params => req.get('/api/policyLaw/detail', params),


}
